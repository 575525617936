import { Component, Input, OnInit } from "@angular/core";

// @ts-ignore
import RFB from "@novnc/novnc/core/rfb";

@Component({
  selector: "app-console",
  standalone: true,
  imports: [],
  templateUrl: "./console.component.html",
  styleUrl: "./console.component.scss",
})
export class ConsoleComponent implements OnInit {
  rfb: any;
  ngOnInit(): void {}
  externalWindow: Window;
  _connect = (url: string) => {
    this.rfb = new RFB(
      this.externalWindow.document.getElementById("screen"),
      url
    );
    const targetNode = this.externalWindow.document.getElementById("screen");
    const config = { attributes: false, childList: true, subtree: false };
    const callback = (mutationsList: any, observer: any) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          observer.disconnect();
          this.externalWindow.close();
        }
      }
    };
    const observer = new MutationObserver(callback);
    if (targetNode) {
      observer.observe(targetNode, config);
    }
  };

  sendCtrAltDel() {
    if (this.rfb) {
      this.rfb.sendCtrlAltDel();
    }
  }
}
