import { Component, Input } from "@angular/core";
import { CartExtra, CartItem } from "../../../../Core/models/Cart";
import { Product } from "../../../../Core/models/Product";
import { ApiService } from "../../../../Core/Services/Api/api.service";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { Currency } from "../../../../Core/models/Currency";
import { CurrencyService } from "../../../../Core/Services/Currency/currency.service";

@Component({
  selector: "app-trader-price-panel",
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: "./price-panel.component.html",
  styleUrl: "./price-panel.component.css",
})
export class PricePanelComponent {
  constructor(public currencyService: CurrencyService) {}

  @Input() Product: Product;
  @Input() Extras: CartExtra[];

  @Input() netto_price: number = 0;
  @Input() currency?: Currency;
  @Input() selectdPeriod: number = 1;
  selectedPricetype: number = 1;
}
