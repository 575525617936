import { Component } from "@angular/core";
import { RouterLink } from "@angular/router";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalModule,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-vps-ids-info",
  standalone: true,
  imports: [TranslateModule, RouterLink],
  templateUrl: "./vps-ids-info.component.html",
  styleUrl: "./vps-ids-info.component.scss",
})
export class VpsIdsInfoComponent {
  constructor(private modal: NgbModal) {}
  modalClose() {
    this.modal.dismissAll();
  }
}
