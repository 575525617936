import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { UserService } from "../Services/User/user.service";
import { map } from "rxjs";
import { environment } from "../../../environments/environment";

export const isDev: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router: Router = inject(Router);
  if (environment.production) {
    return router.createUrlTree(["/"]);
  }
  return true;
};
