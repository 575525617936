<section class="section vps-config-section" *ngIf="vpsData">
  <div class="config-section mb-3">
    <app-config-panel
      [params]="vpsData.params"
      [extras]="vpsData.productextra"
      [title]="vpsData.sname"
      [id]="vpsData.id"
      [period]="selectedPeriod"
      (periodChange)="periodChanged($event)"
      (onExtraChange)="extraValueChanged($event)"
      (cantAddToCart)="cantAddToCart($event)"
    ></app-config-panel>
    <app-trader-price-panel
      [netto_price]="nettoPrice"
      [currency]="currencyService.getCurrentCurrency()"
      [selectdPeriod]="selectedPeriod"
    ></app-trader-price-panel>
    <button (click)="addToCart()" *ngIf="canAddToCart">
      {{ "vps-page.add_to_cart" | translate }}
    </button>
    <label
      *ngIf="!canAddToCart"
      class="text-center"
      style="
        background-color: black;
        color: white;
        padding: 10px;
        font-size: 1rem;
      "
    >
      {{ "vps-page.cant_add_to_cart" | translate }}
    </label>
  </div>
</section>
