<nav
  class="navbar bg-dark border-bottom border-body navbar-expand-lg bg-body-tertiary"
  data-bs-theme="dark"
>
  <div class="container-fluid">
    <a class="navbar-brand me-auto" [routerLink]="['/']">
      <img src="{{ menuLogo }}" alt="logo" width="60" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      (click)="isMenuCollapsed = !isMenuCollapsed"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      [ngbCollapse]="isMenuCollapsed"
      class="collapse justify-content-center navbar-collapse"
      id="navbarSupportedContent"
    >
      <ul class="navbar-nav gap-2">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" [routerLink]="['/']">{{
            "menu.home" | translate
          }}</a>
        </li>
        <li ngbDropdown class="nav-item">
          <button type="button" class="nav-link" ngbDropdownToggle>
            {{ "menu.services" | translate }}
          </button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem [routerLink]="['/vps']">
              {{ "menu.vps" | translate }}
            </button>
            <button ngbDropdownItem [routerLink]="['/dedicated']">
              {{ "menu.dedicated" | translate }}
            </button>
            <button ngbDropdownItem [routerLink]="['/servercolocation']">
              {{ "menu.servercolocation" | translate }}
            </button>
            <button ngbDropdownItem [routerLink]="['/eduservice']">
              {{ "menu.eduservice" | translate }}
            </button>
            <button ngbDropdownItem [routerLink]="['/system-integration']">
              {{ "menu.system-integration" | translate }}
            </button>
            <button ngbDropdownItem [routerLink]="['/automatization']">
              {{ "menu.automatization" | translate }}
            </button>
            <button
              ngbDropdownItem
              [routerLink]="['/classic-hosting-services']"
            >
              {{ "menu.classic-hosting-services" | translate }}
            </button>
            <button ngbDropdownItem [routerLink]="['/trader']" *ngIf="!isProd">
              {{ "menu.trader" | translate }}
            </button>
          </div>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/blogs']">
            {{ "menu.blog" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/about']">
            {{ "menu.about" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/faq']">
            {{ "menu.faq" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            [href]="
              (isProd ? 'basic.contact.link' : 'basic.contact.link_test')
                | translate
            "
            target="_blank"
          >
            {{ "menu.contact" | translate }}
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/cart']">
            {{ "menu.cart" | translate }}
            <span
              class="badge text-bg-primary"
              *ngIf="cartService.getItems().length > 0"
              >{{ cartService.getItems().length }}</span
            >
          </a>
        </li>
        <li class="nav-item" *ngIf="!(userService.isLoggedIn | async)">
          <button type="button" class="nav-link" (click)="openUserOffCanvas()">
            {{ "menu.login" | translate }}
          </button>
        </li>
        <li class="nav-item" *ngIf="!(userService.isLoggedIn | async)">
          <button type="button" class="nav-link" [routerLink]="['/register']">
            {{ "menu.register" | translate }}
          </button>
        </li>
        <li ngbDropdown class="nav-item" *ngIf="userService.isLoggedIn | async">
          <button type="button" class="nav-link" ngbDropdownToggle>
            <fa-icon [icon]="faUser"></fa-icon> :
            {{ userService.name | async }}
          </button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem [routerLink]="['/user/orders']">
              {{ "menu.user.orders" | translate }}
            </button>
            <button ngbDropdownItem [routerLink]="['/user/subscriptions']">
              {{ "menu.user.subscriptions" | translate }}
            </button>
            <button ngbDropdownItem [routerLink]="['/user/services']">
              {{ "menu.user.services" | translate }}
            </button>
            <button ngbDropdownItem [routerLink]="['/user/invoices']">
              {{ "menu.user.invoices" | translate }}
            </button>
            <button ngbDropdownItem [routerLink]="['/user/profile']">
              {{ "menu.user.profile" | translate }}
            </button>
            <button ngbDropdownItem [routerLink]="['/user/support']">
              {{ "menu.user.support" | translate }}
            </button>
            <button ngbDropdownItem (click)="logout()">
              {{ "menu.user.logout" | translate }}
            </button>
          </div>
        </li>

        <li ngbDropdown class="nav-item">
          <button type="button" class="nav-link" ngbDropdownToggle>
            <img
              src="/assets/images/flags/{{ currentLang }}.svg"
              class="circle-image"
              width="25px"
            />
          </button>
          <div ngbDropdownMenu>
            <button
              ngbDropdownItem
              (click)="changeLange(language.code, language.id)"
              *ngFor="let language of languages"
            >
              <img
                src="/assets/images/flags/{{ language.code }}.svg"
                class="circle-image"
                width="20px"
              />
              -
              {{ "language." + language.code | translate }}
            </button>
          </div>
        </li>

        <li ngbDropdown class="nav-item">
          <button type="button" class="nav-link" ngbDropdownToggle>
            {{ currencyService.getCurrentCurrency()?.name }}
          </button>
          <div ngbDropdownMenu>
            <button
              ngbDropdownItem
              (click)="changeCurrency(currency.id)"
              *ngFor="let currency of currencyService.currencies"
            >
              {{ currency.name }} - {{ currency.shortname }}
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
