import { Component, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChildrenOutletContexts, RouterOutlet } from "@angular/router";
import { MenuComponent } from "./Core/menu/menu.component";
import { FooterComponent } from "./Core/footer/footer.component";
import { MainComponent } from "./Pages/main/main.component";
import { ToastComponent } from "./Core/Toast/toast/toast.component";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ClmCookieService } from "./Core/Services/Cookie/clm-cookie.service";
import { slideInAnimation } from "./Core/Animations/slideInAnimation";
import { ScriptService } from "./Core/Services/Script/script.service";
import { environment } from "../environments/environment";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    MenuComponent,
    FooterComponent,
    ToastComponent,
    TranslateModule,
  ],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
  animations: [slideInAnimation],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "CLM-PORTAL";

  constructor(
    private translateService: TranslateService,
    private cookiService: ClmCookieService,
    private contexts: ChildrenOutletContexts,
    private scriptService: ScriptService,
    private cookieService: ClmCookieService
  ) {}
  langLoanded = false;
  onLangChange?: Subscription;

  getRouteAnimationData() {
    return this.contexts.getContext("primary")?.route?.snapshot?.data?.[
      "animation"
    ];
  }

  ngOnInit(): void {
    this.translateService.addLangs(["en", "hu"]);
    this.translateService.setDefaultLang("en");

    if (this.cookiService.check("language")) {
      if (
        this.translateService
          .getLangs()
          .find((v) => v == this.cookiService.get("language"))
      ) {
        //this.translateService.defaultLang = this.cookiService.get('language');
        this.translateService.use(this.cookiService.get("language"));
      }
    }
    if (!this.cookiService.check("language")) {
      if (
        this.translateService
          .getLangs()
          .find((v) => v == this.translateService.getBrowserLang())
      ) {
        const lang: string = this.translateService.getBrowserLang() || "en";
        //this.translateService.defaultLang = lang;
        this.translateService.use(lang);
      } else {
        //this.translateService.defaultLang = 'en';
        this.translateService.use("en");
      }
    }
    //this.cookiConsetService.initialize();
    this.onLangChange = this.translateService.onLangChange.subscribe((val) => {
      this.langLoanded = true;
      document.documentElement.lang = val.lang;
      //this.cookieService.set("language", this.translateService.currentLang);
      if (this.translateService.currentLang == "hu") {
        this.scriptService.load("leadconnectorhq_hu").then((data) => {});
      } else {
        this.scriptService.load("leadconnectorhq_en").then((data) => {});
      }
    });
  }

  ngOnDestroy() {
    //this.cookiConsetService.cleanUp();
    this.onLangChange?.unsubscribe;
    // unsubscribe to cookieconsent observables to prevent memory leaks
  }
}
