import { EventEmitter, Injectable } from "@angular/core";
import { Cart, CartItem } from "../../models/Cart";

@Injectable({
  providedIn: "root",
})
export class CartService {
  Cart: Cart = { items: [] };
  cart_str: string = "cart";

  order_success: boolean = false;
  public onLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    this.loadCart();
  }

  addToCart(addedItem: CartItem, period: number | undefined = undefined) {
    if (period && !this.Cart.period) {
      this.Cart.period = period;
    }
    this.Cart.items.push(addedItem);
    this.saveCart();
  }

  getItems() {
    return this.Cart.items;
  }

  getIds() {
    return this.Cart.items.map((o) => o.productid);
  }

  loadCart(): void {
    let CartJSON = localStorage.getItem(this.cart_str);
    if (CartJSON) {
      this.Cart = JSON.parse(CartJSON) ?? [];
    } else {
      this.Cart = { items: [] };
    }
    this.onLoaded.emit(true);
  }

  saveCart(): void {
    localStorage.setItem(this.cart_str, JSON.stringify(this.Cart));
  }

  clearCart(): void {
    this.Cart = { items: [] };
    localStorage.removeItem(this.cart_str);
  }

  removeItem(item: CartItem) {
    const index = this.Cart.items.findIndex(
      (o) => o.productid == item.productid
    );
    if (index > -1) {
      this.Cart.items.splice(index, 1);
      if (this.Cart.items.length == 0) {
        this.Cart.period = undefined;
      }
      this.saveCart();
    }
  }

  itemInCart(item: CartItem): boolean {
    return (
      this.Cart.items.findIndex((o) => o.productid === item.productid) > -1
    );
  }

  prodInCartCount(id: number): number {
    return this.Cart.items.filter((o) => o.productid === id).length;
  }
}
