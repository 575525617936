import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  inject,
} from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  RouterLink,
} from "@angular/router";
import {
  NgbCollapseModule,
  NgbDropdownModule,
  NgbOffcanvas,
  OffcanvasDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "../Services/User/user.service";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { CartService } from "../Services/Cart/cart-service";
import { UserCanvasComponent } from "./user-canvas/user-canvas.component";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ClmCookieService } from "../Services/Cookie/clm-cookie.service";
import { Observable, Subscription, interval } from "rxjs";
import { ApiService } from "../Services/Api/api.service";
import { Language } from "../models/Language";
import { CurrencyService } from "../Services/Currency/currency.service";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-menu",
  standalone: true,
  imports: [
    NgbCollapseModule,
    NgbDropdownModule,
    RouterLink,
    FormsModule,
    CommonModule,
    FontAwesomeModule,
    UserCanvasComponent,
    TranslateModule,
  ],
  templateUrl: "./menu.component.html",
  styleUrl: "./menu.component.scss",
})
export class MenuComponent implements OnInit, OnDestroy {
  faUser = faUser;
  menuLogo: string = "/assets/images/clmaster-logo.png";
  languages?: Language[] = [];
  intervalSubscription: Observable<number>;
  isProd = environment.production;

  constructor(
    public userService: UserService,
    private router: Router,
    public cartService: CartService,
    public translateService: TranslateService,
    private cookieService: ClmCookieService,
    private apiService: ApiService,
    public currencyService: CurrencyService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isMenuCollapsed = true;
      }
    });
  }
  ngOnDestroy(): void {
    this.onLangChanged.unsubscribe();
  }

  ngOnInit(): void {
    this.apiService.getLanguages().subscribe({
      next: (response) => {
        this.languages = response.data;
      },
    });
    this.currentLang = this.translateService.currentLang;
    this.onLangChanged = this.translateService.onLangChange.subscribe((val) => {
      this.currentLang = val.lang;
    });
    this.intervalSubscription = interval(10000);
    this.intervalSubscription.subscribe((x) => {
      this.userService.isLoggedIn.subscribe({
        next: (value) => {
          if (value) {
            this.apiService.GetUser().subscribe({
              next: (response) => {
                if (!response.result) {
                  this.userService.logout();
                }
              },
            });
          }
        },
      });
    });
  }

  private onLangChanged!: Subscription;
  currentLang?: string;
  userEmail: string = "";
  userPassword: string = "";

  isMenuCollapsed = true;
  private offcanvasService = inject(NgbOffcanvas);
  closeResult = "";
  openUserOffCanvas() {
    this.offcanvasService
      .open(UserCanvasComponent, {
        ariaLabelledBy: "offcanvas-basic-title",
        position: "end",
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    switch (reason) {
      case OffcanvasDismissReasons.ESC:
        return "by pressing ESC";
      case OffcanvasDismissReasons.BACKDROP_CLICK:
        return "by clicking on the backdrop";
      default:
        return `with: ${reason}`;
    }
  }

  login() {
    const activatedRouteSnapshot: ActivatedRouteSnapshot = inject(
      ActivatedRouteSnapshot
    );
    this.userService.login(this.userEmail, this.userPassword).subscribe({
      next: (value) => {
        if (value.result && value.data) {
          localStorage.setItem("apitoken", value.data);
          this.offcanvasService.dismiss();
          this.userService.getUserData();
        }
      },
    });
  }

  logout() {
    this.userService.logout();
    this.router.navigate([""]);
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl("/langchange").then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  changeCurrency(currencyId: number) {
    this.cookieService.set("currency", currencyId.toString());
    this.userService.isLoggedIn.subscribe({
      next: (value) => {
        if (value) {
          this.userService.id.subscribe({
            next: (id) => {
              this.apiService
                .changeCustomerCurrency(
                  currencyId,
                  this.userService.customerId,
                  id
                )
                .subscribe({
                  next: (response) => {
                    if (response.result) {
                      localStorage.setItem("apitoken", response.data);
                      this.currencyService.onChange.emit(true);
                    }
                  },
                });
            },
          });
        } else {
          this.apiService.changeCustomerCurrency(currencyId).subscribe({
            next: (response) => {
              if (response.result) {
                localStorage.setItem("apitoken", response.data);
                this.currencyService.onChange.emit(true);
              }
            },
          });
        }
      },
    });
  }

  changeLange(lang: string, langId: number) {
    this.cookieService.set("language", lang);
    this.currentLang = lang;
    this.userService.id.subscribe({
      next: (id) => {
        this.apiService.changeLanguage(langId, id).subscribe({
          next: (response) => {
            if (response.result) {
              localStorage.setItem("apitoken", response.data);
              this.translateService.use(lang);
            }
          },
        });
      },
    });
  }
}
