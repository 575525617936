import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ApiService } from "../../../../Core/Services/Api/api.service";
import { VPS, VPSInfo } from "../../../../Core/models/VPS";
import { CommonModule } from "@angular/common";
import { ToastService } from "../../../../Core/Toast/toast.service";
import { TranslateModule } from "@ngx-translate/core";
import { CapitalizePipe } from "../../../../Core/Pipes/Capitalize/capitalize.pipe";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faInfoCircle,
  faPlay,
  faPowerOff,
  faPlug,
  faArrowsRotate,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";
import { TimePipe } from "../../../../Core/Pipes/Time/time.pipe";
import { Subscription, empty, timer } from "rxjs";
import { NgbModal, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from "ngx-cookie-service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CdkPortal, ComponentPortal, DomPortalHost } from "@angular/cdk/portal";
import { ConsoleService } from "../../../../Core/Services/Console/console.service";
import { ConsoleComponent } from "./console/console.component";
import { environment } from "../../../../../environments/environment";
import { VpsInfoComponent } from "./vps-info/vps-info.component";
import { FormsModule } from "@angular/forms";
import { PasswordComponent } from "../password/password.component";
import { VpsIdsInfoComponent } from "./vps-ids-info/vps-ids-info.component";

@Component({
  selector: "app-vps",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CapitalizePipe,
    TimePipe,
    FontAwesomeModule,
    NgbTooltipModule,
    CdkPortal,
    VpsInfoComponent,
    FormsModule,
  ],
  templateUrl: "./vps.component.html",
  styleUrl: "./vps.component.scss",
})
export class VpsComponent implements OnInit, OnDestroy {
  @Input() vps?: VPSInfo;
  vps_info?: VPSInfo;
  InfoIcon = faInfoCircle;
  runningTime: number = 0;
  operation?: string;
  clean_shutdown: boolean = false;
  hard_shutdown: boolean = false;
  start: boolean = false;
  reboot: boolean = false;
  hard_reboot: boolean = false;
  isConsoleOpen = false;
  startIcon = faPlay;
  stopIcon = faPowerOff;
  forceIcon = faBolt;
  forceStopIcon = faPlug;
  rebootIcon = faArrowsRotate;
  infoIcon = faInfoCircle;
  serverTime?: number;
  isCEVPS: boolean = false;
  @Output() onError = new EventEmitter<number>();
  @Output() onLoaded = new EventEmitter<boolean>();

  constructor(
    private apiService: ApiService,
    private toastService: ToastService,
    private cookieService: CookieService,
    private jwtService: JwtHelperService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    private consoleService: ConsoleService,
    private modalService: NgbModal
  ) {}

  ngOnDestroy(): void {
    this.refreshSub?.unsubscribe();
    this.runningTimeSub?.unsubscribe();
  }

  xoa_token: string = "";

  async ngOnInit() {
    let apiToken = localStorage.getItem("apitoken");
    if (apiToken) {
      this.xoa_token = this.jwtService.decodeToken(apiToken).xoatoken;
    }

    await this.refreshVpsInfo();
    this.runningTimeInc();
  }

  refreshSub?: Subscription;

  runningTimeSub?: Subscription;
  runningTimeInc() {
    const time = timer(1000);
    this.runningTimeSub = time.subscribe(() => {
      if (this.vps_info?.startTime) {
        if (this.serverTime) this.runningTime = this.runningTime + 1000;
      }
      this.runningTimeInc();
    });
  }

  async refreshVpsInfo() {
    await this.getVpsInfo();
    const time = timer(5000);
    this.refreshSub = time.subscribe(() => {
      this.refreshVpsInfo();
    });
  }

  async getVpsInfo() {
    if (this.vps) {
      if (
        this.vps.sts == "C" ||
        this.vps.sts == "U" ||
        this.vps.sts == "R" ||
        this.vps.sts == "L"
      ) {
        this.apiService.getVPSInfo(this.vps.id!).subscribe({
          next: (response) => {
            if (!response.result) {
              this.onError.emit(this.vps?.id);
            }
            this.onLoaded.emit(true);
            if (response.result) {
              if (this.vps?.sts == "C" || this.vps?.sts == "U") {
                this.serverTime = Date.parse(response.servertime);
                this.clean_shutdown = false;
                this.hard_shutdown = false;
                this.start = false;
                this.reboot = false;
                this.hard_reboot = false;
                this.operation = undefined;
                this.isCEVPS = response.data.name_label
                  ? response.data.name_label.includes("CEVPS")
                  : true;
                if (!this.proxyDomain || this.proxyNeedUpdate) {
                  if (response.data.domain) {
                    this.proxyDomain = response.data.domain;
                  } else {
                    this.proxyDomain = `${response.data.name_label}.vps.clmaster.cloud`;
                  }
                  this.proxyNeedUpdate = false;
                }

                if (
                  Array.isArray(response.data?.os_version) &&
                  response.data?.os_version.length == 0
                ) {
                  response.data.os_version = null;
                }
                if (!Array.isArray(response.data?.current_operations)) {
                  Object.getOwnPropertyNames(
                    response.data?.current_operations
                  ).forEach((val) => {
                    if (
                      response.data?.current_operations[val] == "clean_shutdown"
                    ) {
                      this.clean_shutdown = true;
                      this.operation = "clean_shutdown";
                    }
                    if (
                      response.data?.current_operations[val] == "hard_shutdown"
                    ) {
                      this.hard_shutdown = true;
                      this.operation = "hard_shutdown";
                    }
                    if (response.data?.current_operations[val] == "start") {
                      this.start = true;
                      this.operation = "start";
                    }
                    if (
                      response.data?.current_operations[val] == "clean_reboot"
                    ) {
                      this.reboot = true;
                      this.operation = "clean_reboot";
                    }
                    if (
                      response.data?.current_operations[val] == "hard_reboot"
                    ) {
                      this.hard_reboot = true;
                      this.operation = "hard_reboot";
                    }
                  });
                }
                this.vps_info = response.data;
                if (this.vps_info?.startTime) {
                  this.runningTime =
                    this.serverTime - Date.parse(this.vps_info?.startTime);
                }
              } else if (this.vps?.sts == "R" || this.vps?.sts == "L") {
                this.vps_info = response.data;
              }
            }
          },
          error: (error) => {
            console.log(this.vps);
          },
        });
      }
    }
  }

  stopVps() {
    if (this.vps && this.canStopVps()) {
      this.apiService.stopVPS(this.vps?.id!).subscribe({
        next: (response) => {
          if (response.error) {
            this.toastService.showAlert(response.error);
            return;
          }
          this.clean_shutdown = true;
          this.getVpsInfo();
        },
      });
    }
  }

  canStopVps(): boolean {
    return (
      this.vps_info?.power_state == "Running" &&
      !(this.clean_shutdown || this.hard_shutdown || this.start)
    );
  }

  forceStopVps() {
    if (this.vps && this.canForceStopVps()) {
      this.apiService.forceStopVPS(this.vps?.id!).subscribe({
        next: (response) => {
          if (response.error) {
            this.toastService.showAlert(response.error);
            return;
          }
          this.hard_shutdown = true;
          this.getVpsInfo();
        },
      });
    }
  }

  canForceStopVps(): boolean {
    return this.vps_info?.power_state != "Halted" && !this.hard_reboot;
  }

  startVps() {
    if (this.vps && this.canStartVps()) {
      this.apiService.startVPS(this.vps?.id!).subscribe({
        next: (response) => {
          if (response.error) {
            this.toastService.showAlert(response.error);
            return;
          }
          this.start = true;
          this.getVpsInfo();
        },
      });
    }
  }

  VpsRunning() {
    return this.vps_info?.power_state == "Running";
  }

  canStartVps(): boolean {
    return (
      this.vps_info?.power_state == "Halted" &&
      !(
        this.start ||
        this.hard_shutdown ||
        this.hard_reboot ||
        this.clean_shutdown
      )
    );
  }

  rebootVps() {
    if (this.vps && this.canRebootVps()) {
      this.apiService.rebootVPS(this.vps?.id!).subscribe({
        next: (response) => {
          if (response.error) {
            this.toastService.showAlert(response.error);
            return;
          }
          this.reboot = true;
          this.getVpsInfo();
        },
      });
    }
  }

  canRebootVps(): boolean {
    return (
      this.vps_info?.power_state == "Running" &&
      !(
        this.reboot ||
        this.hard_reboot ||
        this.clean_shutdown ||
        this.hard_shutdown ||
        this.start
      )
    );
  }

  forceRebootVps() {
    if (this.vps && this.canForceRebootVps()) {
      this.apiService.forceRebootVPS(this.vps?.id!).subscribe({
        next: (response) => {
          if (response.error) {
            this.toastService.showAlert(response.error);
            return;
          }
          this.hard_reboot = true;
          this.getVpsInfo();
        },
      });
    }
  }

  canForceRebootVps(): boolean {
    return (
      this.vps_info?.power_state != "Halted" &&
      (!this.hard_shutdown || this.hard_reboot)
    );
  }

  openConsole() {
    if (!this.xoa_token) {
      const dialogRef = this.modalService.open(PasswordComponent, {
        centered: true,
      });
      dialogRef.closed.subscribe((result) => {
        if (result) {
          this.xoa_token = result;
          this.openConsole();
        }
      });

      return;
    }

    let externalWindow = window.open("", "", "width=1024,height=768");
    if (externalWindow) {
      if (this.vps_info?.name_label) {
        externalWindow.document.title = this.vps_info?.name_label;
      }

      this.consoleService.addConsole(externalWindow);
      const host = new DomPortalHost(
        externalWindow.document.body,
        this.componentFactoryResolver,
        this.applicationRef,
        this.injector
      );

      let portal = new ComponentPortal(ConsoleComponent, null, null);

      const ref = host.attachComponentPortal(portal);
      ref.instance.externalWindow = externalWindow;
      const hostURI = "test-xoa.clmaster.cloud";
      const port = "30443";
      const path = "api/consoles";
      let protocol = "wss";
      let url = `${protocol}://${environment.xoaUri}${path}/${this.vps_info?.UUID}/${this.xoa_token}`;
      ref.instance._connect(url);
    }
  }

  vpsProxy(status: string) {
    if (this.vps?.id == undefined) return;
    this.apiService.vpsProxy(this.vps?.id, status).subscribe({
      next: (response) => {
        if (response.error) {
          this.toastService.showAlert(response.error);
          return;
        }
        this.proxyNeedUpdate = true;
        this.getVpsInfo();
      },
    });
  }

  vpsIds(status: number) {
    if (this.vps?.id == undefined) return;
    this.apiService.vpsIds(this.vps?.id, status).subscribe({
      next: (response) => {
        if (response.error) {
          this.toastService.showAlert(response.error);
          return;
        }
        this.getVpsInfo();
      },
    });
  }

  proxyDomain: string = "";
  proxyNeedUpdate: boolean = false;
  onProxyDomainChange() {
    if (this.vps?.id == undefined) return;
    this.apiService.vpsProxy(this.vps?.id, "ON", this.proxyDomain).subscribe({
      next: (response) => {
        if (response.error) {
          this.toastService.showAlert(response.error);
          return;
        }
        this.toastService.showAlert("SUCCESS");
        this.proxyNeedUpdate = true;
        this.getVpsInfo();
      },
    });
  }

  openProxyInfo() {
    let modalref = this.modalService.open(VpsInfoComponent, {
      centered: true,
    });
  }

  openIDSInfo() {
    let modalref = this.modalService.open(VpsIdsInfoComponent, {
      centered: true,
    });
  }
}
