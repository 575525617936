import { Component, OnInit, ViewChild, viewChild } from "@angular/core";
import { ApiService } from "../../../Core/Services/Api/api.service";
import { CommonModule } from "@angular/common";
import { VpsComponent } from "./vps/vps.component";
import { VPS, VPSInfo } from "../../../Core/models/VPS";
import { UserService } from "../../../Core/Services/User/user.service";
import { TranslateModule } from "@ngx-translate/core";
import { faShield, faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-services",
  standalone: true,
  host: {
    class: "flex-shrink-0 flex-grow-1",
  },
  imports: [
    CommonModule,
    VpsComponent,
    TranslateModule,
    FontAwesomeModule,
    NgbTooltipModule,
  ],
  templateUrl: "./services.component.html",
  styleUrl: "./services.component.scss",
})
export class ServicesComponent {
  @ViewChild(VpsComponent) vpsComponent?: VpsComponent;
  services?: VPS[] = [];
  servicesWithData: VPSInfo[] = [];
  loaded: boolean = false;
  selectedService?: VPSInfo = undefined;
  idsIcon = faShield;
  proxyIcon = faShieldAlt;
  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) {
    apiService.getVPSes().subscribe({
      next: (response) => {
        if (response.error) {
          this.userService.logout();
        }
        if (response.result) {
          this.services = response.data?.filter(
            (x) => x.sts == "C" || x.sts == "U" || x.sts == "R" || x.sts == "L"
          );
          this.services.forEach((service) => {
            this.getServiceData(service);
          });
        }
      },
    });
  }

  getServiceData(service: VPS) {
    this.apiService.getVPSInfo(service.id).subscribe({
      next: (response) => {
        var vpsInfo = response.data;
        vpsInfo.id = service.id;
        vpsInfo.name = service.name;
        vpsInfo.sts = service.sts;
        vpsInfo.os_version.icon = this.getOsIcon(vpsInfo.os_version.distro);
        console.log(vpsInfo);
        this.servicesWithData.push(response.data);
        if (!this.selectedService) {
          this.serviceSelected(this.servicesWithData[0]);
        }
      },
    });
  }

  getOsIcon(name: string): string {
    switch (name) {
      case "ubuntu":
        return "ubuntu.svg";
      case "debian":
        return "debian.svg";
      case "alma":
        return "linux.svg";
      case "oracle":
        return "oracle-linux.svg";
      case "fedora":
        return "fedora.svg";
      case "rocky":
        return "Rocky_Linux.svg";
      case "centos":
        return "centos.svg";
      case "opensuse":
        return "opensuse.svg";
      case "windows":
        return "windows.svg";
      default:
        return "";
    }
  }

  serviceSelected(service: VPSInfo) {
    this.loaded = false;
    this.selectedService = service;
    if (this.vpsComponent) {
      this.vpsComponent.vps = service;
    }
    this.vpsComponent?.ngOnDestroy();
    this.vpsComponent?.ngOnInit();
  }

  onLoaded() {
    this.loaded = true;
  }
}
