import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  withInMemoryScrolling,
} from "@angular/router";

import { routes } from "./app.routes";
import { provideAnimations } from "@angular/platform-browser/animations";
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { JwtHelperService, JwtModule } from "@auth0/angular-jwt";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: "top",

  anchorScrolling: "enabled",
};
const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

import {
  NgcCookieConsentConfig,
  provideNgcCookieConsent,
} from "ngx-cookieconsent";
import { BrowserModule } from "@angular/platform-browser";
import { environment } from "../environments/environment";
import { DecimalPipe } from "@angular/common";

export const appConfig: ApplicationConfig = {
  providers: [
    DecimalPipe,
    provideRouter(routes, inMemoryScrollingFeature),
    provideAnimations(),
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter: () => {
            let helper = new JwtHelperService();
            let token = localStorage.getItem("apitoken");
            if (token) {
              try {
                let data = helper.decodeToken(token);
              } catch (error) {
                localStorage.removeItem("apitoken");
                token = null;
              }
            }

            if (!token) {
              token = localStorage.getItem("gtoken");
              if (token) {
                try {
                  let data = helper.decodeToken(token);
                } catch (error) {
                  localStorage.removeItem("gtoken");
                  token = null;
                }
              }
            }
            return token;
          },
          headerName: "X-API-KEY",
          skipWhenExpired: true,
          authScheme: "",
          allowedDomains: [environment.apiHost],
          disallowedRoutes: [],
        },
      })
    ),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
    provideHttpClient(withInterceptorsFromDi()),
  ],
};
