<ng-container *ngIf="!services || services.length == 0">
  <h2 class="text-center">{{ "vps.no_service" | translate }}</h2>
</ng-container>
<ng-container *ngIf="services && services.length > 0">
  <div class="service-container section">
    <div class="service-sidebar">
      <ul class="service-list">
        <li
          *ngFor="let service of servicesWithData"
          (click)="serviceSelected(service)"
          id="service-{{ service.id }}"
          [ngClass]="selectedService?.id === service.id ? 'selected' : ''"
        >
          <img
            *ngIf="service.os_version.icon"
            style="width: 30px; height: 30px"
            src="assets/images/os/{{ service.os_version.icon }}"
            [ngbTooltip]="service.os_version.name | translate"
          />
          {{ service.name }}
          <fa-icon
            [icon]="idsIcon"
            class="text-primary fs-3"
            *ngIf="service.ids == 1"
            [ngbTooltip]="'vps.ids.run' | translate"
          ></fa-icon>
          <fa-icon
            [icon]="proxyIcon"
            class="text-success fs-3"
            [ngbTooltip]="'vps.proxy.run' | translate"
            *ngIf="service.proxy == 'ON'"
          ></fa-icon>
        </li>
      </ul>
    </div>
    <div class="service-content">
      <div
        class="d-flex justify-content-center"
        style="height: 90vh"
        *ngIf="!loaded"
      >
        <div
          class="spinner-grow text-primary"
          style="
            width: 5rem;
            height: 5rem;
            margin-top: auto;
            margin-bottom: auto;
          "
        >
          <span class="visually-hidden"></span>
        </div>
      </div>
      <app-vps
        [vps]="selectedService"
        (onLoaded)="onLoaded()"
        [hidden]="!loaded"
      ></app-vps>
    </div>
  </div>
</ng-container>
