<div class="container p-4">
  <p>{{ "vps.ids.info.p_1" | translate }}</p>
  <ul>
    <li>{{ "vps.ids.info.li_1" | translate }}</li>
    <li>{{ "vps.ids.info.li_2" | translate }}</li>
    <li>{{ "vps.ids.info.li_3" | translate }}</li>
    <li>{{ "vps.ids.info.li_4" | translate }}</li>
  </ul>
  <p>{{ "vps.ids.info.p_2" | translate }}</p>
  <p>{{ "vps.ids.info.p_3" | translate }}</p>
  <a
    (click)="modalClose()"
    [routerLink]="[
      'blogs',
      'Miert_erdemes_bekapcsolni_az_IDS_es_GeoIP_szurest_a_VPSen'
    ]"
    >{{ "vps.ids.info.link" | translate }}</a
  >
</div>
