import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class ClmCookieService {
  constructor(private ngxCookieService: CookieService) {}

  set(
    key: string,
    value: string,
    expire: number = 365 * 24 * 60 * 60 * 1000
  ): void {
    const now = new Date();
    const expires = new Date(now.getTime() + expire);
    this.ngxCookieService.set(key, value, expires, "/");
  }

  check(key: string): boolean {
    return this.ngxCookieService.check(key);
  }

  get(key: string): string {
    return this.ngxCookieService.get(key);
  }

  removeCookies(): void {
    this.ngxCookieService.deleteAll();
  }
}
